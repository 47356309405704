<template>
  <section id="dashboard">
    <section class="mt-lg-5 mt-4">
      <mdb-row>
        <!-- Start Card -->
        <mdb-col xl="4" md="6" class="mb-r" v-for="program in programes" :key="program.id">
          <mdb-card cascade class="cascading-admin-card">
            <div class="admin-up pl-lg-3 pr-lg-3">
              <mdb-row>
                <mdb-col sm="12" md="7" style="">
                  <mdb-card-image :src="program.program_logo" style="background-color : #007bff ; background-shaborder: 1px solid;padding: 3px;box-shadow: 3px 3px #d1d1d1; display: block; margin-left: auto; margin-right: auto; width: 190px;" alt="Card image cap" />
                </mdb-col>
                <mdb-col sm="12" md="5" class="pt-lg-5 text-center">
                  <a :href=" program.program_url ">
                    <mdb-btn size="sm">Buka Aplikasi</mdb-btn>
                  </a>
                </mdb-col>
              </mdb-row>
            </div>
            <!-- <div class="admin-right">
            </div> -->
            <mdb-card-body>
              <mdb-card-text><h4>{{ program.program_name }}</h4></mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!-- End Card -->
      </mdb-row>
    </section>
  </section>
</template>

<script>
import {
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardImage,
  mdbCardBody,
  mdbBtn,
  mdbCardText
} from 'mdbvue'
import axios from 'axios'

export default {
  name: 'Dashboard',
  components: {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardImage,
    mdbCardBody,
    mdbBtn,
    mdbCardText
  },
  data() {
    return {
      programes: []
    }
  },

  async created() {
    this.load()
  },
  methods: {

    async load() {
      const response = await axios.get('https://master-config.madani-tech.com/api/Admin?id_yayasan=125')
      // console.log(response)
      this.programes = response.data
    }
  }
}
</script>

<style scoped>
.cascading-admin-card {
  margin: 20px 0;
}
.cascading-admin-card .admin-up {
  /* margin-left: 4%;
  margin-right: 10%; */
  margin-top: -20px;
}
.admin-right {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: -35px;
  margin-bottom: 0px;
}

.cascading-admin-card .admin-up .fas,
.cascading-admin-card .admin-up .far {
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
  padding: 1.7rem;
  font-size: 2rem;
  color: #fff;
  text-align: left;
  margin-right: 1rem;
  border-radius: 3px;
}
.cascading-admin-card .admin-up .data {
  float: right;
  margin-top: 2rem;
  text-align: right;
}
.admin-up .data p {
  color: #999999;
  font-size: 12px;
}
.btn
.classic-admin-card .card-body {
  color: #fff;
  margin-bottom: 0;
  padding: 0.9rem;
}
.classic-admin-card .card-body p {
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 0;
}
.classic-admin-card .card-body h4 {
  margin-top: 10px;
}

.btn.btn-sm {
    padding: .3rem 1.0rem !important;
    font-size: .68rem;
}

</style>
